import React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { LayoutProvider, Wrapper } from "../../components/layout";
import KeyReplacement from "../../components/sections/KeyAndCombinationForm/KeyReplacement";
import { stackStyled } from "../../theme";
import { useViewPortSize } from "../../hooks";
import { StackOnBreadcrumbs } from "../../atoms";
import { ResponsiveContainer } from "../../atoms/Containers";

const query = graphql`
  query {
    strapiKeyReplacement {
      title
      subTitle
      steps
      note
    }
  }
`;
export interface IKeyReplacementDetails {
  title: string;
  subTitle: string;
  steps: string;
  note: string;
}
interface IKeyReplacement {
  strapiKeyReplacement: IKeyReplacementDetails;
}

const BreadCrumbsContainer = stackStyled(
  "div",
  (props: { isMobile: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? "40px" : "inherit",
    left: props.isMobile ? "15px" : "75px",
    width: props.isMobile ? "90%" : "380px",
    textAlign: "left",
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    maxWidth: "1280px",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    padding: props.isMobile ? "100px 10px" : "50px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
  })
);

const KeysReplacement: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useViewPortSize();
  const data = useStaticQuery<IKeyReplacement>(query);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            noMarginTop={true}
            pages={[
              { title: "Home", href: "/" },
              { title: "Customer service", href: "/customer-service" },
              {
                title: "Keys Replacement",
                href: "/customer-service/keys-replacement",
              },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <KeyReplacement
            title={data.strapiKeyReplacement.title}
            subTitle={data.strapiKeyReplacement.subTitle}
            steps={data.strapiKeyReplacement.steps}
            note={data.strapiKeyReplacement.note}
          />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacement;