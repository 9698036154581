import React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { useViewPortSize } from "../../../../hooks";
import { IFileImage } from "../../../../@types";
import ReactMarkdown from "react-markdown";
import { Button } from "../../../../atoms";
import { stackStyled, Typography, useStackStyletron } from "../../../../theme";
import EmailIcon from '-!svg-react-loader!../../../../assets/email-contact.svg';

// QUERY

const query = graphql`
  query {
    strapiLostAssistance {
      applicationSuccessfulMessage
      applicationSuccessfulSteps {
        subtitle
        title
        icon {
          url
        }
      }
    }
  }
`;

interface Props {}

interface IStepApplication {
  title: string;
  subtitle: string;
  icon: IFileImage;
}

export interface ILostAssistanceApplication {
  applicationSuccessfulMessage: string;
  applicationSuccessfulSteps: IStepApplication[];
}
interface IData {
  strapiLostAssistance: ILostAssistanceApplication;
}

const MainContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: 0,
    marginTop: isMobile ? "15px" : "50px",
    width: "100%",
    height: "100%",
  })
);

const FlexContainer = stackStyled(
  "div",
  ({ direction }: { direction?: "column" | "row" }) => ({
    display: "flex",
    flexDirection: direction,
  })
);

export const LostAssistanceSuccessful = ({ email = "" }: { email: string }) => {
  // HOOKS
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();

  // STATE

  // HANLDERS && FUNCTIONS

  // RENDER

  const { strapiLostAssistance = {} as ILostAssistanceApplication } = data;
  const {
    applicationSuccessfulMessage,
    applicationSuccessfulSteps = [] as IStepApplication[],
  } = strapiLostAssistance;

  return (
    <MainContainer
      isMobile={isMobile}
      role="region"
      aria-label="Lost Assistance Form Successful"
    >
      <EmailIcon/>
      <ReactMarkdown
        components={{
          h2: ({ node, ...props }) => (
            <Typography
              $fontFamily={"Montserrat"}
              $weight={700}
              $tagType={"h4"}
              $textAlign={"center"}
              $color={theme.stackOnColors.primaryGreenDark}
              {...props}
            />
          ),
          p: ({ node, ...props }) => (
            <Typography
              $fontFamily={"Montserrat"}
              $weight={400}
              $tagType={"body"}
              $textAlign={"center"}
              $color={theme.stackOnColors.primaryBlack}
              {...props}
            />
          ),
          a: ({ node, ...props }) => (
            <a
              style={{
                color: theme.stackOnColors.primaryGreenLight,
                fontWeight: 400,
              }}
              {...props}
            >
              {email}
            </a>
          ),
        }}
      >
        {applicationSuccessfulMessage}
      </ReactMarkdown>
      <FlexContainer direction="row" style={{ justifyContent: "center", marginTop: isMobile ? '15px' : '30px' }}>
        <div style={{ width: "fit-content" }}>
          <Button.RoundedButton
            onClick={() => (window.location.href = "/")}
            size="compact"
            color="primary"
            fullWidth
          >
            Back to home
          </Button.RoundedButton>
        </div>
      </FlexContainer>
    </MainContainer>
  );
};

export default LostAssistanceSuccessful;

